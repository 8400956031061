import { defineAsyncComponent } from 'vue';

export const ShieldLock = defineAsyncComponent(
  () => import('@/components/icons/tablericons/ShieldLock.vue')
);

export const FloatLeft = defineAsyncComponent(
  () => import('@/components/icons/tablericons/FloatLeft.vue')
);

export const ListCheck = defineAsyncComponent(
  () => import('@/components/icons/tablericons/ListCheck.vue')
);

export const ArrowsRandom = defineAsyncComponent(
  () => import('@/components/icons/tablericons/ArrowsRandom.vue')
);

export const FilterOff = defineAsyncComponent(
  () => import('@/components/icons/tablericons/FilterOff.vue')
);

export const FilterOutline = defineAsyncComponent(
  () => import('@/components/icons/tablericons/FilterOutline.vue')
);

export const FilterSolid = defineAsyncComponent(
  () => import('@/components/icons/tablericons/FilterSolid.vue')
);

export const KeyboardIcon = defineAsyncComponent(
  () => import('@/components/icons/tablericons/KeyboardIcon.vue')
);

export const PlayerSkipForward = defineAsyncComponent(
  () => import('@/components/icons/tablericons/PlayerSkipForward.vue')
);

export const ArrowsSplit = defineAsyncComponent(
  () => import('@/components/icons/tablericons/ArrowsSplit.vue')
);

export const LetterCase = defineAsyncComponent(
  () => import('@/components/icons/tablericons/LetterCase.vue')
);

export const PinnedFilled = defineAsyncComponent(
  () => import('@/components/icons/tablericons/PinnedFilled.vue')
);

export const PinnedIcon = defineAsyncComponent(
  () => import('@/components/icons/tablericons/PinnedIcon.vue')
);

export const DragDrop = defineAsyncComponent(
  () => import('@/components/icons/tablericons/DragDrop.vue')
);

export const BrandPagekit = defineAsyncComponent(
  () => import('@/components/icons/tablericons/BrandPagekit.vue')
);

export const CalendarEvent = defineAsyncComponent(
  () => import('@/components/icons/tablericons/CalendarEvent.vue')
);

export const CalendarRepeat = defineAsyncComponent(
  () => import('@/components/icons/tablericons/CalendarRepeat.vue')
);
